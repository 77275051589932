import React from "react";
import CuratedService from "./CuratedService";
function App() {
  return (
    <div className="App">
      <CuratedService />
    </div>
  );
}

export default App;
